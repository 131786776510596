import React from "react";

import SEO from "../components/seo";

const NotFoundPage = () => (
  <>
    <SEO title="404: Not found" />
    <section className="page-utility">
      <div className="container">
        <nav aria-label="breadcrumb">
          <ol className="breadcrumb">
            <li className="breadcrumb-item ">
              <a
                ad="BC"
                data-ad_click="BC"
                data-event_type="ilink"
                href="https://www.thomasnet.com"
              >
                Home
              </a>
            </li>
            <li className="breadcrumb-item active">Page Not Found</li>
          </ol>
        </nav>
        <div id="utillinks" />
      </div>
    </section>
    <section className="legacy-content" data-thdoc="S0">
      <div className="container">
        <div id="tnet_body" className="tnet_wrap">
          <div id="tnet_content" className="logged-out">
            <div
              style={{
                display: "flex",
                flexFlow: "column",
                alignItems: "center"
              }}
            >
              <img
                src="//cdn2.hubspot.net/hub/242200/hubfs/1MARCOMM/Campaigns/PYB_16_006_404_Error_Page_words_search.png?t=1469108847181&amp;width=331&amp;name=PYB_16_006_404_Error_Page_words_search.png"
                alt="PYB_16_006_404_Error_Page_words_search.png"
                width="331"
                sizes="(max-width: 331px) 100vw, 331px"
              />

              <h2 style={{ marginTop: "1em" }}>
                The page you were looking for can't be found.
              </h2>
            </div>
          </div>
        </div>
      </div>
    </section>
  </>
);

export default NotFoundPage;
